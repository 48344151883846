import React, { useEffect } from "react";
import Customer from "./components/Customer/Customer";
import Login from "./components/Login/Login";

import { useStateValue } from "./StateContext";
import UserData from "./components/User/UserData";
import Logout from "./components/Logout/Logout";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Modal,
    Paper,
    Typography,
} from "@mui/material";

import image from "./Atradius-Logo_262x70.jpg";

import { ReducerTypes } from "./api/reducer";

function App() {
    const { state, dispatch } = useStateValue();

    useEffect(() => {
        const token = sessionStorage.getItem("token");
        if (token) {
            dispatch({ type: ReducerTypes.LOGIN, payload: token });
        }
        const msgText = sessionStorage.getItem("msgText");
        if (msgText) {
            dispatch({ type: ReducerTypes.MSGTEXT, payload: msgText });
        }
        const employee = sessionStorage.getItem("employee");
        if (employee) {
            dispatch({ type: ReducerTypes.USEREMAIL, payload: employee });
        }
    }, []);

    const handleModulButtonClick = () => {
        dispatch({ type: ReducerTypes.MODALOPEN, payload: false });
        dispatch({ type: ReducerTypes.LOADING, payload: false });
        dispatch({ type: ReducerTypes.RESPONSE, payload: false });
    };

    if (state.token !== null) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Paper variant="elevation" sx={{ padding: "10px" }}>
                        <div className="App px-3 mt-3">
                            <div className="row mb-3">
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <img
                                        src={image}
                                        alt="Atradius Logo"
                                        style={{
                                            width: "100%",
                                            maxWidth: "262px",
                                            marginBottom: "1rem",
                                        }}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginBottom: "1rem",
                                        }}
                                    >
                                        <UserData />
                                        <Logout />
                                    </div>
                                </div>
                            </div>
                            <Customer />
                        </div>
                    </Paper>
                </Grid>
                <Modal open={state.modal.open}>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{ height: "100vh" }}
                    >
                        <Paper style={{ padding: 20 }}>
                            {state.modal.loading ? (
                                <>
                                    <Typography variant="body">
                                        Bitte warten...
                                    </Typography>
                                    <Box px={5} py={2}>
                                        <CircularProgress />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Typography variant="body">
                                        {state.modal.success
                                            ? "Triggermail wird zur gewünschten Zeit versendet."
                                            : "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."}
                                    </Typography>
                                    <Box pt={2} style={{ textAlign: "center" }}>
                                        <Button
                                            variant="contained"
                                            onClick={handleModulButtonClick}
                                        >
                                            Ok
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </Paper>
                    </Grid>
                </Modal>
            </Grid>
        );
    } else {
        return (
            <div className="App">
                <Login />
            </div>
        );
    }
}

export default App;
