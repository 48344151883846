import React, { useState } from "react";
import { TextField, Button, Grid, Typography, Paper } from "@mui/material";

import ApiRequest from "../../api/ApiRequest";
import URI from "../../api/uri";
import { useStateValue } from "../../StateContext";
import { ReducerTypes } from "../../api/reducer";

const LoginForm = () => {
    const { state, dispatch } = useStateValue();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const handleLoginButton = async () => {
        try {
            const json = await ApiRequest({ username, password }, URI.LOGIN);
            if (!json.success || json.token == "") {
                setError("Login fehlgeschlagen.");
                return;
            }
            dispatch({ type: ReducerTypes.LOGIN, payload: json.token });
            dispatch({ type: ReducerTypes.MSGTEXT, payload: json.data });
            dispatch({ type: ReducerTypes.USEREMAIL, payload: username });
            setError("");
        } catch (error) {
            setError("Login fehlgeschlagen.");
        }
    };

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: "100vh" }}
        >
            <Grid item xs={12} sm={8} md={6} lg={6}>
                <Paper style={{ padding: 20 }}>
                    <Typography variant="h5" gutterBottom>
                        Login
                    </Typography>
                    <TextField
                        label="Username"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        error={error !== ""}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={error !== ""}
                        helperText={error}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleLoginButton}
                        className="mt-3"
                    >
                        Login
                    </Button>
                    <Button
                        variant="outlined"
                        className="mt-5"
                        onClick={() => {
                            const subject = "Passwort vergessen";
                            const body =
                                "Bitte setzen Sie mein Passwort zurück.\n\nUsername: hier Benutzername eintragen\n\n\nMit freundlichen Grüßen";
                            window.location.href = `mailto:Stephan.Ahlgrim@atradius.com?subject=${encodeURIComponent(
                                subject
                            )}&body=${encodeURIComponent(body)}`;
                        }}
                        sx={{ borderColor: "transparent" }}
                    >
                        <Typography variant="body2" textTransform={"initial"}>
                            Passwort vergessen?
                        </Typography>
                    </Button>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default LoginForm;
