import React, { useState } from "react";
import { Button, Grid, Modal, Paper, Typography } from "@mui/material";
import { useStateValue } from "../../StateContext";

const UserData = () => {
    const { state, dispatch } = useStateValue();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleOpen} variant="outlined">
                Meine Daten
            </Button>

            <Modal open={open}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} xl={6}>
                        <Paper
                            style={{
                                padding: 20,
                                width: "100%",
                                margin: "auto",
                            }}
                        >
                            <h2>Impressum</h2>
                            <div className="col-12 justify"></div>
                            <textarea
                                className="col-12 p-3"
                                style={{
                                    height: 400,
                                    borderColor: "transparent",
                                }}
                                defaultValue={state.user.msgText}
                                readOnly
                            />
                            <p>
                                Nötige Änderungen bitte per{" "}
                                <a
                                    href="mailto:Stephan.Ahlgrim@atradius.com?subject=Webmaske%20Daten%20ändern&body=Bitte%20ändern%20Sie%20meine%20Daten%20wie%20folgt:`"
                                    target="_blank"
                                    title="Daten ändern"
                                >
                                    E-Mail
                                </a>
                            </p>
                            <div className="d-flex justify-content-end">
                                <Button
                                    onClick={handleClose}
                                    variant="contained"
                                >
                                    <Typography variant="button">
                                        Schliessen
                                    </Typography>
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Modal>
        </div>
    );
};

export default UserData;
