import React, { useState } from "react";
import { useStateValue } from "../../StateContext";
import { Box, Button, Grid, Modal, Paper } from "@mui/material";
import { ReducerTypes } from "../../api/reducer";

function Logout() {
    const { state, dispatch } = useStateValue();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        dispatch({ type: ReducerTypes.LOGOUT });
    };

    return (
        <div>
            <Button onClick={handleOpen} variant="outlined" className="mx-1">
                Logout
            </Button>

            <Modal open={open} onClose={handleClose}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: "100vh" }}
                >
                    <Paper style={{ padding: 20 }}>
                        <p>Wollen Sie sich wirklich abmelden?</p>
                        <Box sx={{ display: "flex" }}>
                            <Button
                                variant="outlined"
                                onClick={handleClose}
                                className="btn btn-secondary"
                            >
                                Nein
                            </Button>
                            <Box flex={1} />
                            <Button
                                variant="contained"
                                onClick={handleLogout}
                                className="btn btn-primary"
                            >
                                Ja
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Modal>
        </div>
    );
}

export default Logout;
