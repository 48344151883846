import React from "react";
import "dayjs/locale/de";
import dayjs from "dayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { useStateValue } from "../../StateContext";
import { ReducerTypes } from "../../api/reducer";

dayjs.locale("de");

const dateFormat = "YYYY-MM-DD HH:mm:ss";

function DateTime() {
    const { state, dispatch } = useStateValue();
    const handleTimeChange = (date) => {
        if (date === null) return;
        dispatch({ type: ReducerTypes.TIME, payload: date.format(dateFormat) });
    };

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    label="Sendezeit einstellen (optional)"
                    ampm={false}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                    }}
                    format={dateFormat}
                    onChange={handleTimeChange}
                    defaultValue={dayjs().add(5, "minute")} // Set default value 5 minutes in the future
                />
            </LocalizationProvider>
        </div>
    );
}

export default DateTime;
