import React, { useEffect, useState } from "react";
import URI from "../../api/uri";
import ApiRequest from "../../api/ApiRequest";
import { useStateValue } from "../../StateContext";
import { ReducerTypes } from "../../api/reducer";

function CampaignList(props) {
    const { state, dispatch } = useStateValue();
    const { setCampaign } = props;
    const [campaigns, setCampaigns] = useState([]);
    useEffect(() => {
        const fetchCampaigns = async () => {
            const json = await ApiRequest(
                { request: "getCampaigns", token: state.token },
                URI.GET_CAMPAIGNS
            );
            setCampaigns((prevState) => [json.data]);
        };
        fetchCampaigns();
    }, []);

    const handleCampaignChange = (e) => {
        dispatch({ type: ReducerTypes.CID, payload: e.target.value });
        dispatch({ type: ReducerTypes.PROFILFIELD, payload: e.target.id });
        setCampaign(true);
    };

    return (
        <div>
            <h4>
                Thema auswählen <span className="fs-5">(pflicht)</span>
            </h4>
            <div className="row">
                {campaigns?.map((array) =>
                    array.map((campaign, index) => (
                        <div
                            className="col-xs-12 col-md-6 col-lg-6 mb-3 form-check"
                            key={campaign.cid}
                        >
                            <input
                                className="form-check-input"
                                type="radio"
                                name="campaign"
                                value={campaign.cid}
                                key={campaign.cid}
                                id={campaign.name}
                                onChange={handleCampaignChange}
                            />
                            <label
                                htmlFor={campaign.name}
                                className="form-check-label"
                            >
                                {campaign.label}
                            </label>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}

export default CampaignList;
