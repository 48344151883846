import React, { useRef, useState } from "react";
import { useStateValue } from "../../StateContext";

import ApiRequest from "../../api/ApiRequest";
import URI from "../../api/uri";
import CampaignList from "../Campaign/CampaignList";
import DateTime from "../DateTime/DateTime";
import { ReducerTypes } from "../../api/reducer";

const StatusMsg = {
    None: 0,
    New: 1,
    Active: 2,
    Inactive: 3,
};

function Customer() {
    const { state, dispatch } = useStateValue();
    const [profilRequest, setProfilRequest] = useState(false);
    const [statusMsg, setStatusMsg] = useState(StatusMsg.None);
    const [validEmail, setValidEmail] = useState(false);
    const [campaign, setCampaign] = useState(false);
    const [email, setEmail] = useState("");

    const isValidEmail = (email) => {
        // Regulärer Ausdruck für die Email-Validierung
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (email) => {
        setEmail(email);
        setValidEmail(false);
        if (isValidEmail(email)) {
            setValidEmail(true);
            dispatch({ type: ReducerTypes.EMAIL, payload: email });
        }
    };
    const handleSalutChange = (salut) => {
        dispatch({ type: ReducerTypes.SALUT, payload: salut });
    };
    const handleFirstChange = (first) => {
        dispatch({ type: ReducerTypes.FIRST, payload: first });
    };
    const handleLastChange = (last) => {
        dispatch({ type: ReducerTypes.LAST, payload: last });
    };

    const handleButtonEmailCheckClick = async () => {
        if (!validEmail || email.length === 0) {
            return;
        }

        setCampaign(false);
        setProfilRequest(false);
        dispatch({ type: ReducerTypes.LOADING, payload: true });
        dispatch({ type: ReducerTypes.MODALOPEN, payload: true });

        const json = await ApiRequest(
            { email: state.profil.email, token: state.token },
            URI.CHECK_EMAIL
        );

        setProfilRequest(true);
        setStatusMsg(StatusMsg.New);
        if (json.success) {
            dispatch({ type: ReducerTypes.SALUT, payload: json.data.salut });
            dispatch({ type: ReducerTypes.FIRST, payload: json.data.first });
            dispatch({ type: ReducerTypes.LAST, payload: json.data.last });
            dispatch({ type: ReducerTypes.STATUS, payload: json.data.status });
            dispatch({ type: ReducerTypes.RID, payload: json.data.rid });
            if (json.data.status === "a") {
                setStatusMsg(StatusMsg.Active);
            }
            if (json.data.status !== "a") {
                setStatusMsg(StatusMsg.Inactive);
                setProfilRequest(false);
            }
        } else {
            dispatch({ type: ReducerTypes.RESET });
            dispatch({ type: ReducerTypes.EMAIL, payload: email });
        }
        dispatch({ type: ReducerTypes.LOADING, payload: false });
        dispatch({ type: ReducerTypes.MODALOPEN, payload: false });
    };

    const handleSubmitData = async () => {
        dispatch({ type: ReducerTypes.MODALOPEN, payload: true });
        dispatch({ type: ReducerTypes.LOADING, payload: true });
        const data = {
            token: state.token,
            email: state.profil.email,
            salut: state.profil.salut,
            first: state.profil.first,
            last: state.profil.last,
            rid: state.profil.rid,
            status: state.profil.status,
            cid: state.profil.cid,
            time: state.profil.time,
            msgText: state.user.msgText,
            employee: state.user.email,
            datasource: state.profil.datasource,
            field: state.profil.field,
        };
        const json = await ApiRequest(data, URI.SUBMIT_TRIGGER_DATA);
        dispatch({ type: ReducerTypes.LOADING, payload: false });
        dispatch({ type: ReducerTypes.RESPONSE, payload: json.success });
        doReset();
    };

    const doReset = () => {
        dispatch({ type: ReducerTypes.RESET });
        setStatusMsg(StatusMsg.None);
        setProfilRequest(false);
        setCampaign(false);
        setValidEmail(false);
        setEmail("");
    };

    return (
        <div className="mb-3">
            <h4>Kunde:</h4>
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="input-group">
                        <input
                            value={email}
                            type="email"
                            name="email"
                            placeholder="E-Mail Adresse"
                            className={
                                validEmail && email.length > 0
                                    ? "form-control alert alert-success"
                                    : "form-control alert alert-danger"
                            }
                            style={{ marginBottom: "0" }}
                            onChange={(e) => handleEmailChange(e.target.value)}
                        />
                        <button
                            className="btn btn-success"
                            type="button"
                            onClick={handleButtonEmailCheckClick}
                        >
                            Überprüfen
                        </button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    {statusMsg == StatusMsg.New ? (
                        <div className="alert alert-warning" role="alert">
                            Die E-Mail Adresse existiert aktuell nicht. Zum
                            Anlegen des Profils wie folgt fortfahren.
                        </div>
                    ) : null}
                    {statusMsg == StatusMsg.Active ? (
                        <div className="alert alert-success" role="alert">
                            Die E-Mail Adresse existiert mit folgenden Daten.
                            Die Daten können wie folgt aktualisiert werden.
                        </div>
                    ) : null}
                    {statusMsg == StatusMsg.Inactive ? (
                        <div className="alert alert-danger" role="alert">
                            Die E-Mail Adresse existiert mit einem aktuell
                            nicht-aktiven Status und darf daher nicht
                            angeschrieben werden.
                            <br />
                            <br />
                            <a href="mailto:Stephan.Ahlgrim@atradius.com">
                                Kontakt für weitere Details
                            </a>
                        </div>
                    ) : null}
                </div>
            </div>
            {profilRequest ? (
                <>
                    <h4 className="mt-2">
                        Kundendaten <span className="fs-5">(optional)</span>
                    </h4>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label htmlFor="salut" className="form-label">
                                Anrede
                            </label>
                            <select
                                name="salut"
                                className="form-select"
                                onChange={(e) =>
                                    handleSalutChange(e.target.value)
                                }
                                value={state.profil.salut}
                            >
                                <option value="">Bitte auswählen</option>
                                <option value="Herr">Herr</option>
                                <option value="Frau">Frau</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label htmlFor="first" className="form-label">
                                Vorname
                            </label>
                            <input
                                type="text"
                                name="first"
                                value={state.profil.first}
                                onChange={(e) =>
                                    handleFirstChange(e.target.value)
                                }
                                placeholder="Vorname"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <label htmlFor="last" className="form-label">
                                Nachname
                            </label>
                            <input
                                type="text"
                                name="last"
                                value={state.profil.last}
                                onChange={(e) =>
                                    handleLastChange(e.target.value)
                                }
                                placeholder="Nachname"
                                className="form-control"
                            />
                        </div>
                    </div>

                    {statusMsg !== StatusMsg.Inactive ||
                    statusMsg !== StatusMsg.None ? (
                        <>
                            <div className="row">
                                <div className="col-12 mb-5 mt-2">
                                    <CampaignList setCampaign={setCampaign} />
                                </div>
                            </div>
                            {campaign ? (
                                <>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <DateTime />
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                            <button
                                                type="button"
                                                className="btn btn-primary w-100 btn-success mt-4"
                                                onClick={handleSubmitData}
                                            >
                                                Senden
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </>
                    ) : null}
                </>
            ) : null}
        </div>
    );
}

export default Customer;
