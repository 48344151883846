export const initialState = {
    token: null,
    loading: false,
    profil: {
        email: "",
        salut: "",
        first: "",
        last: "",
        rid: "",
        status: "",
        cid: "",
        time: "",
        datasource: "Webmaske",
        field: "",
    },
    user: {
        msgText: "",
        email: "",
    },
    modal: {
        open: false,
        loading: false,
        response: false,
    },
};

export const ReducerTypes = {
    RESPONSE: "RESPONSE",
    MODALOPEN: "MODALOPEN",
    LOADING: "LOADING",
    RID: "RID",
    MSGTEXT: "MSGTEXT",
    USEREMAIL: "USEREMAIL",
    TIME: "TIME",
    CID: "CID",
    PROFILFIELD: "PROFILFIELD",
    RESET: "RESET",
    LOGOUT: "LOGOUT",
    LOGIN: "LOGIN",
    STATUS: "STATUS",
    EMAIL: "EMAIL",
    SALUT: "SALUT",
    FIRST: "FIRST",
    LAST: "LAST",
};

export const reducer = (state, action) => {
    switch (action.type) {
        case ReducerTypes.RESPONSE:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    success: action.payload,
                },
            };
        case ReducerTypes.MODALOPEN:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    open: action.payload,
                },
            };
        case ReducerTypes.LOADING:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    loading: action.payload,
                },
            };
        case ReducerTypes.RID:
            return {
                ...state,
                profil: {
                    ...state.profil,
                    rid: action.payload,
                },
            };
        case ReducerTypes.MSGTEXT:
            sessionStorage.setItem("msgText", action.payload);
            return {
                ...state,
                user: {
                    ...state.user,
                    msgText: action.payload,
                },
            };
        case ReducerTypes.USEREMAIL:
            sessionStorage.setItem("employee", action.payload);
            return {
                ...state,
                user: {
                    ...state.user,
                    email: action.payload,
                },
            };
        case ReducerTypes.TIME:
            return {
                ...state,
                profil: {
                    ...state.profil,
                    time: action.payload,
                },
            };
        case ReducerTypes.CID:
            return {
                ...state,
                profil: {
                    ...state.profil,
                    cid: action.payload,
                },
            };
        case ReducerTypes.PROFILFIELD:
            return {
                ...state,
                profil: {
                    ...state.profil,
                    field: action.payload,
                },
            };
        case ReducerTypes.RESET:
            return {
                ...state,
                profil: {
                    email: "",
                    salut: "",
                    first: "",
                    last: "",
                    rid: "",
                    status: "",
                    cid: "",
                    time: "",
                    datasource: "Webmaske",
                    field: "",
                },
            };
        case ReducerTypes.LOGOUT:
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("msgText");
            sessionStorage.removeItem("employee");
            return initialState;
        case ReducerTypes.LOGIN:
            sessionStorage.setItem("token", action.payload);
            return {
                ...state,
                token: action.payload,
            };
        case ReducerTypes.STATUS:
            return {
                ...state,
                profil: {
                    ...state.profil,
                    status: action.payload,
                },
            };
        case ReducerTypes.EMAIL:
            return {
                ...state,
                profil: {
                    ...state.profil,
                    email: action.payload,
                },
            };
        case ReducerTypes.SALUT:
            return {
                ...state,
                profil: {
                    ...state.profil,
                    salut: action.payload,
                },
            };
        case ReducerTypes.FIRST:
            return {
                ...state,
                profil: {
                    ...state.profil,
                    first: action.payload,
                },
            };
        case ReducerTypes.LAST:
            return {
                ...state,
                profil: {
                    ...state.profil,
                    last: action.payload,
                },
            };
        default:
            return {
                ...state,
            };
    }
};
